const logotext = "Afsar Arif";
const meta = {
    title: "Afsar Arif",
    description: "I am a freshman pursuing computer engineering at the University of Texas at Dallas",
};

const introdata = {
    title: "I’m Afsar Arif",
    animated: {
        first: "I love coding",
        second: "I code cool websites",
        third: "I develop mobile apps",
    },
    description: "I am a freshman pursuing computer engineering at the University of Texas at Dallas",
    your_img_url: "./1.png",
};

const dataabout = {
    title: "A bit about my self",
    aboutme: "I am a freshman pursuing computer engineering at the University of Texas at Dallas. I am a proficient software developer  in html, css, javascript, java, react  and a beginner in python. I am a prolific UX designer experienced in spline, photoshop and after effects. I have been implementing progressive web applications for the past 5 years. I  am a continuous learner  improving my skills in emerging technologies, leadership, communication, critical thinking, and problem solving.",
};
const worktimeline = [{
        jobtitle: "First Lego Leauge(FLL) Coach",
        where: "SMARTS Club Frisco",
        date: "2019",
    },
];

const skills = [{
        name: "Python",
        value: 75,
    },
    {
        name: "Photoshop,Spline,Adobe After Effects",
        value: 95,
    },
    {
        name: "Javascript,Html,CSS",
        value: 95,
    },
    {
        name: "React",
        value: 95,
    },
    {
        name: "Word,Powerpoint,Excel",
        value: 95,
    },
];

const services = [{
        title: "UI & UX Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Web Applications",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
];

const dataportfolio = [{
        img: "./2.png",
        desctiption: "I was inspired to create a simple music player for leaked songs of certain hip-hop artists by using react as the front end and mongodb and firebase as the backend.",
        link: "https://muttleaks.web.app/",
    },
    {
        img: "./4.png",
        desctiption: "A project that's focused on building the fundamentals of a MERN stack app. This project is currently being wroked on.",
        link: "",
    },
    {
        img: "./6.png",
        desctiption: "A fun little 3D design originally designed to use on initial protfolio and learn more about spline",
        link: "https://my.spline.design/untitled-52c678d810c5f240b5757cfe274849bb/",
    },
    {
        img: "./7.png",
        desctiption: "A fun little 3D design originally designed to learn more about spline",
        link: "https://my.spline.design/earth-77477ac43a0ae4c5d2e7db616afc8998/",
    },
];

const contactConfig = {
    YOUR_EMAIL: "mohamedafsar.arif@gmail.com",
    YOUR_FONE: "(469)-636-2699",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_im3n901",
    YOUR_TEMPLATE_ID: "template_7qgmggk",
    YOUR_USER_ID: "VsfTM9RaSLkBdVg4K",
};

const socialprofils = {
    github: "https://github.com/Afsartech",
    linkedin: "https://www.linkedin.com/in/afsar-arif-150b711b9/",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};
